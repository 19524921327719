import { render } from 'react-dom';

import './style.scss';
import Style from './Style';

// Business Card
document.addEventListener('DOMContentLoaded', () => {
	const allBusinessCard = document.querySelectorAll('.wp-block-business-card');
	allBusinessCard.forEach(businessCard => {
		const attributes = JSON.parse(businessCard.dataset.attributes);

		render(<>
			<Style attributes={attributes} clientId={attributes.cId} />

			<BusinessCard attributes={attributes} />
		</>, businessCard);

		businessCard?.removeAttribute('data-attributes');
	});
});

const BusinessCard = ({ attributes }) => {
	const { name, title, contacts, theme, isHeaderSep } = attributes;

	return <div className={`bcbBusinessCard ${theme}`}>
		<div className='header'>
			{name && <h3 className='name' dangerouslySetInnerHTML={{ __html: name }} />}

			{title && <p className='title' dangerouslySetInnerHTML={{ __html: title }} />}

			{isHeaderSep && <span className='separator'></span>}
		</div>

		<div className='contacts'>
			{contacts?.length && contacts.map((contact, index) => {
				const { icon, text } = contact;

				return <div key={index} className='contact'>
					{icon?.class && <i className={`icon ${icon?.class}`}></i>}
					<p className='text' dangerouslySetInnerHTML={{ __html: text }} />
				</div>
			})}
		</div>
	</div>
}