import { getBackgroundCSS, getBorderCSS, getColorsCSS, getSeparatorCSS, getShadowCSS, getSpaceCSS, getTypoCSS } from '../../Components/Helper/getCSS';

const Style = ({ attributes, clientId }) => {
	const { alignment, width, background, padding, border, shadow, headerAlign, headerMargin, nameTypo, nameColor, titleTypo, titleColor, headerSep, contactIconColors, contactTextTypo, contactTextColor } = attributes;

	const mainSl = `#bcbBusinessCard-${clientId}`;
	const businessCardSl = `${mainSl} .bcbBusinessCard`;
	const headerSl = `${businessCardSl} .header`;
	const contactsSl = `${businessCardSl} .contacts`;

	return <style dangerouslySetInnerHTML={{
		__html: `
		${getTypoCSS('', nameTypo)?.googleFontLink}
		${getTypoCSS('', titleTypo)?.googleFontLink}
		${getTypoCSS('', contactTextTypo)?.googleFontLink}
		${getTypoCSS(`${headerSl} .name`, nameTypo)?.styles}
		${getTypoCSS(`${headerSl} .title`, titleTypo)?.styles}
		${getTypoCSS(`${contactsSl} .text`, contactTextTypo)?.styles}

		${mainSl}{
			text-align: ${alignment};
		}
		${businessCardSl}{
			width: ${'0px' === width || '0%' === width || '0em' === width ? 'auto' : width};
			${getBackgroundCSS(background)}
			padding: ${getSpaceCSS(padding)};
			${getBorderCSS(border)}
			box-shadow: ${getShadowCSS(shadow)};
		}
		${headerSl}{
			justify-items: ${headerAlign};
			margin: ${getSpaceCSS(headerMargin)};
		}
		${headerSl} .name{
			color: ${nameColor};
		}
		${headerSl} .title{
			color: ${titleColor};
		}
		${headerSl} .separator{
			${getSeparatorCSS(headerSep)}
		}
		${contactsSl} .icon{
			${getColorsCSS(contactIconColors)}
		}
		${contactsSl} .text{
			color: ${contactTextColor};
		}
		`.replace(/\s+/g, ' ')
	}} />;
}
export default Style;